import { Flex, Grid } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { LoadingMessageStatus, type LoadingMessage } from './types';
import { FiAlertCircle } from 'react-icons/fi';
import Divider from '@odo/components/elements/divider';
import Tooltip from '@odo/components/widgets/tooltip';
import useBulkEditContext from '@odo/contexts/search/bulk-edit/hooks';

const BulkEditLoader = ({
  loadingMessages,
}: {
  loadingMessages: LoadingMessage[];
}) => {
  const successfulSaves = loadingMessages.filter(
    message => message.status === LoadingMessageStatus.success
  ).length;
  const { hasFailedUploads, numDeals } = useBulkEditContext();

  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      gap="20px"
    >
      <Flex justifyContent="center" gap="15px" alignItems="center">
        <Text fontSize={4}>
          {successfulSaves}/{numDeals} deal
          {successfulSaves > 0 && 's'} saved successfully
        </Text>
        <Tooltip
          showDelay={500}
          hideDelay={100}
          content={() =>
            hasFailedUploads
              ? 'Some or all deals failed to save. Your saved deals will remain selected for you to try again.'
              : 'Please do not close this dialog or tab, as you risk interrupting the saving process. Why not check on the upload status of your deals below?'
          }
          color={cssColor(hasFailedUploads ? 'palette-pink' : 'palette-blue')}
          placement="left"
        >
          <FiAlertCircle
            size="2rem"
            color={cssColor('palette-blue')}
            style={{ paddingTop: '2px' }}
          />
        </Tooltip>
      </Flex>
      <Divider />

      {loadingMessages.length > 0 && (
        <Grid gap={[2, 3]} maxHeight="300px" overflowY="scroll">
          <AnimatePresence>
            {loadingMessages.map(message => (
              <motion.div
                key={message.productId}
                layout
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
                style={{ maxWidth: '600px' }}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  gap={2}
                  width="100%"
                >
                  <Text
                    color={cssColor('text')}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {message.label}
                  </Text>

                  {message.status === LoadingMessageStatus.loading && (
                    <IconSpinner
                      width={16}
                      height={16}
                      color={cssColor('palette-blue')}
                    />
                  )}

                  {message.status === LoadingMessageStatus.success && (
                    <IconValid
                      width={16}
                      height={16}
                      color={cssColor('palette-turquoise')}
                    />
                  )}

                  {message.status === LoadingMessageStatus.error && (
                    <IconExclamation
                      width={16}
                      height={16}
                      color={cssColor('palette-pink')}
                    />
                  )}
                </Flex>
              </motion.div>
            ))}
          </AnimatePresence>
        </Grid>
      )}
    </Flex>
  );
};

export default BulkEditLoader;
