import { useCurrentProduct } from '@odo/contexts/product-editor';
import {
  calcProfit,
  getAdminCostValue,
} from '@odo/helpers/calculations/profit/utils';
import { isNumber } from '@odo/types/guards';
import { useMemo } from 'react';

const useProfitCalculation = () => {
  const currentProduct = useCurrentProduct();

  const profit = useMemo(() => {
    if (
      !isNumber(currentProduct.cost?.number) ||
      !isNumber(currentProduct.price?.number) ||
      !isNumber(currentProduct.originalStock?.number)
    ) {
      return;
    }

    /**
     * TODO: remove `|| 0` after upgrading to TS >= 5.3
     */
    return calcProfit({
      cost: currentProduct.cost?.number || 0,
      price: currentProduct.price?.number || 0,
      retail: currentProduct.retail?.number || 0,
      stock: currentProduct.originalStock?.number || 0,
      rebate: currentProduct.rebateDiscount?.number || 0,
      surcharge: currentProduct.surcharge,
      adminCost: getAdminCostValue(currentProduct.adminCost?.id),
      vatIncluded: currentProduct.taxClass?.id !== 'NONE',
    });
  }, [
    currentProduct.cost?.number,
    currentProduct.price?.number,
    currentProduct.retail?.number,
    currentProduct.originalStock?.number,
    currentProduct.rebateDiscount?.number,
    currentProduct.surcharge,
    currentProduct.adminCost?.id,
    currentProduct.taxClass?.id,
  ]);

  return profit;
};

export default useProfitCalculation;
